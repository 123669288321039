import smartlook from 'smartlook-client'

export default {
  install (Vue, options) {
    const smartlookWrapper = {
      track (...args) {
        if (options.enabled) {
          smartlook.track(...args)
        }
      }
    }

    Vue.prototype.$smartlook = smartlookWrapper

    if (options.enabled) {
      smartlook.init(options.projectKey)
    }
  }
}
