import { mapGetters, mapActions } from 'vuex'
import api from '@/lib/api'

export default {
  data () {
    return {
      internalLoading: false,
      notices: {
        somethingWrong: (err) => ({
          theme: 'warning',
          message: `Something went wrong (${err.message}). Please try again.`
        }),
        reclaimSuccess: (tag) => ({
          theme: 'warning',
          message: `Deactivated ${tag}. You may now set this tag aside for collection by ${this.$brand.company}.`
        }),
        reclaimError: (tag, err = {}) => ({
          theme: 'danger',
          message: err.message || `There was a problem deactivating ${tag}. Please try again later.`
        }),
        restoreAvailabilitySuccess: (tag) => ({
          theme: 'success',
          message: `Tag ${tag} is now available to be assigned.`
        }),
        restoreAvailabilityError: (tag, err = {}) => ({
          theme: 'danger',
          message: err.message || `There was a problem resetting tag ${tag} to Available. Please contact support.`
        }),
        activateSuccess: (tag, owner) => ({
          theme: 'success',
          message: `Successfully activated tag ${tag}${owner ? ' for ' + owner : ''}`
        }),
        activateError: (tag, err = {}) => ({
          theme: 'danger',
          message: err.message || `There was a problem activating tag ${tag}. Please contact support.`
        }),
        loadingError: (tag, err = {}) => ({
          theme: 'danger',
          message: err.message || `There was a problem loading data for ${tag}. Please try again.`
        })
      },
      badgeApp: !!window.BadgeAndroidApp,
      lastButtonInfo: { buttonPress: 0, buttonRelease: 0, tapDelta: 0, nonce: 0 }
    }
  },
  computed: {
    ...mapGetters('provision', {
      tagConfig: 'config',
      metadata: 'metadata',
      metadataLoading: 'loading',
      scanMode: 'scanMode',
      tag: 'tag',
      buttonInfo: 'buttonInfo'
    }),
    ...mapGetters('zones', [
      'slugs'
    ]),
    ...mapGetters('distributionStation', {
      stationLoading: 'loading',
      stationId: 'id',
      stationName: 'name',
      stationHelp: 'help',
      adminAppMode: 'adminAppMode'
    }),
    ...mapGetters('auth', [
      'user'
    ]),
    loading () {
      const stationLoading = window.BadgeAndroidApp || this.stationLoading
      return this.internalLoading || this.metadataLoading || stationLoading
    },
    orgName () {
      const zoneName = ((this.user || {}).zone || {}).name
      if (zoneName === 'Organizations') {
        return 'All Organizations'
      }
      return zoneName || 'Unknown'
    },
    formattedMetadata () {
      const m = this.metadata
      if (!m) return
      return {
        'MAC Address': this.tag,
        'Battery State': m.batteryHealth,
        'Last Recording': m.lastSeenFormatted,
        'Firmware Version': `${m.blVersion}.${m.appVersion}`
      }
    }
  },
  methods: {
    ...mapActions('zones', [
      'loadSlugs'
    ]),
    ...mapActions('provision', [
      'setTag',
      'clearTag',
      'setScanMode',
      'pauseScan',
      'fetchTagData'
    ]),
    ...mapActions('distributionStation', [
      'loadStation',
      'clearStation',
      'setAdminAppMode'
    ]),
    refreshInfo () {
      return this.fetchTagData({ tag: this.tag })
    },
    zoneOrgId (location) {
      if (Array.isArray(location)) {
        const org = location.find(l => l.level === 'org')
        return org && org.id
      } else if (location && location.level === 'org') {
        return location.id
      }
    },
    async assignees (query) {
      // If we can see the tag's org, use that to filter fetched people records.
      // If we can't, we assume the user has limited privileges and is only served permission specific people records.
      const peopleOrg = this.tagConfig && this.zoneOrgId(this.tagConfig.location)
      const params = {
        query: JSON.stringify({ name: query, ...(peopleOrg ? { location: peopleOrg } : {}) }),
        limit: 20,
        byColumn: 1,
        orderBy: 'name'
      }
      const { data } = await api.get('people', { params })
      if (data && data.data && data.data.length) {
        return data.data.map(person => ({ id: person.id, title: person.name }))
      }
      return []
    },
    async people (query, org) {
      // If an org is specified, use that to filter fetched people records.
      // If not, we assume the user has limited privileges and is only served permission specific people records.
      const params = {
        query: JSON.stringify({
          $or: [
            { name: { $regex: query, $options: 'i' } },
            { personId: { $regex: query, $options: 'i' } }
          ],
          ...(org ? { location: org } : {})
        }),
        limit: 20,
        byColumn: 1,
        orderBy: 'name',
        collation: JSON.stringify({ locale: 'en' })
      }
      const { data } = await api.get('people', { params })
      if (data && data.data && data.data.length) {
        return data.data.map(person => {
          return {
            id: person.id,
            title: `${person.name} (${person.personId})`
          }
        })
      }
      return []
    },
    async lookupPerson (id, returnFullTags = false) {
      const { data: personData } = await api.get(`people?_id=${id}`)
      if (personData && personData.data && personData.data.length) {
        const person = personData.data[0]
        let tags = null
        const { data: tagData } = await api.get(`tags?config.owner=${person.id}`)
        if (tagData && tagData.data && tagData.data.length) {
          tags = returnFullTags ? tagData.data : tagData.data.map(t => t.id)
        }
        return Promise.resolve({ person, tags })
      } else {
        throw new Error(`${id} not found`)
      }
    },
    printPerson ({ nm, ph }) {
      let info = nm
      if (ph) {
        info += ` with phone number ending in ${ph.slice(-4)}`
      }
      return info
    },
    async finishActivate (tag) {
      try {
        const { data: updated } = await api.put(`tags/${tag}`, { state: 'deployed', station: this.stationId })
        if (!updated || updated.config.state !== 'deployed') {
          console.error('Failed to confirm tag activation')
          throw new Error('DB error')
        }
      } catch (err) {
        console.error('API call to activate tag failed')
        throw err
      }
    },
    activate (tag) {
      this.startLoading()
      return this.finishActivate(tag)
        .finally(() => this.stopLoading())
    },
    async finishInvalidation (tag, targetState) {
      try {
        const { data: updated } = await api.put(`tags/${tag}`, { state: targetState, owner: '', label: '', station: this.stationId })
        if (!updated || updated.config.state !== targetState) {
          console.error('Failed to confirm tag invalidation')
          throw new Error('DB error')
        }
      } catch (err) {
        console.error('API call to invalidate tag failed')
        throw err
      }
    },
    rma (tag) {
      this.startLoading()
      return this.finishInvalidation(tag, 'ready_for_return')
        .finally(() => this.stopLoading())
    },
    async finishRestoreAvailability (tag) {
      try {
        const { data: updated } = await api.put(`tags/${tag}`, { state: 'available', owner: '', label: '', collection: false, station: this.stationId })
        if (!updated || updated.config.state !== 'available') {
          console.error('Failed to confirm tag state change')
          throw new Error()
        }
      } catch (err) {
        console.error('API call to mark tag available failed')
        throw err
      }
    },
    restoreAvailability (tag) {
      this.startLoading()
      return this.finishRestoreAvailability(tag)
        .finally(() => this.stopLoading())
    },
    markLost (tag) {
      this.startLoading()
      this.finishInvalidation(tag, 'lost')
        .finally(() => this.stopLoading())
    },
    async finishAssign (tag, assignee, tagLabel, invoiceZone) {
      try {
        const { data } = await api.put(`tags/${tag}`, {
          state: 'deployed',
          ...(assignee && !!assignee.id && { owner: assignee.id }),
          ...(tagLabel && { label: tagLabel }),
          ...(invoiceZone && { invoiceZone }),
          station: this.stationId
        })
        if (!data || data.config.state !== 'deployed') {
          console.error('Failed to confirm tag assignment/activation')
          throw new Error('DB error')
        } else {
          const updatedConfig = data.config || {}
          return Promise.resolve((updatedConfig.owner || {}).name || updatedConfig.label)
        }
      } catch (err) {
        console.error('API call to assign/activate tag failed')
        throw err
      }
    },
    assign (tag, assignee, tagLabel, invoiceZone) {
      this.startLoading()
      return this.finishAssign(tag, assignee, tagLabel, invoiceZone)
        .finally(() => this.stopLoading())
    },
    async setTagStates (tags) {
      try {
        const { updated } = await api.put('tags', tags.map(t =>
          ({ id: t.id, state: t.state, station: this.stationId }))
        )
        console.log(updated, 'set_tag_states')
      } catch (err) {
        console.error('API call to set tag states failed')
        throw err
      }
    },
    async markTagsForCollection (tags) {
      try {
        const now = new Date()
        const { updated } = await api.put('tags', tags.map(t =>
          ({ id: t, 'collection.reason': 'tag_replaced', 'collection.at': now.toISOString(), station: this.stationId }))
        )
        console.log(updated, 'mark_tags_for_collection')
      } catch (err) {
        console.error('API call to mark tags for collection failed')
        throw err
      }
    },
    startLoading () {
      this.internalLoading = true
    },
    stopLoading () {
      this.internalLoading = false
    },
    onButton (tag, action) {
      if (action === 'assign') {
        this.assign(tag)
      } else if (action === 'activate') {
        this.activate(tag)
      } else if (action === 'reclaim') {
        this.reclaim(tag)
      } else if (action === 'lose') {
        this.markLost(tag)
      } else {
        this.clearPage(tag)
      }
    },
    clearMaintenance (tag) {
      api.put(`tags/${tag}`, { maintMode: false })
        .then((updated) => {
          if (!updated || updated.data.config.maintMode) {
            console.error(`Didn't clear maintMode for ${tag}`)
          }
        })
        .catch((err) => {
          console.error(`Didn't clear maintMode for ${tag}, error ${err}`)
        })
    },
    async loadDistributionStations () {
      const { data } = await api.get('distribution-station')
      if (data && data.data && data.data.length) {
        return data.data.map(station => ({ value: station.id, text: station.name }))
      }
      return []
    },
    async setDistributionStation (station) {
      console.log({ station }, 'station_set')
      if (station) {
        await this.loadStation(station)
      }
    },
    async ensureStation (station) {
      if (station && station !== this.stationId) {
        await this.setDistributionStation(station)
        if (!this.stationId) {
          this.$goSetDefaultParams({})
          this.$go('pick', {
            notice: {
              theme: 'warning',
              message: 'Could not load distribution station. Please pick one.'
            }
          })
        }
        this.$goSetDefaultParams({ station: this.stationId })
      }
    },
    async pollButtonEvents ({ pageMountTime = 0, currentTagOnly = false, skipToLastEvent = false }) {
      const twoMinsAgo = Date.now() - 120000
      const startTime = new Date(twoMinsAgo > pageMountTime ? twoMinsAgo : pageMountTime)
      let eventFilter = `distributionStation=${this.stationId}&createdAt=${JSON.stringify({ $gte: startTime.toISOString() })}&limit=1`
      if (currentTagOnly) {
        eventFilter = `tag=${this.tag}&`.concat(eventFilter)
      }
      const { data: buttonEvents } = await api.get(`button-event?${eventFilter}`)
      console.log(buttonEvents, 'poll_button_events')
      if (buttonEvents && buttonEvents.length) {
        const buttonEvent = buttonEvents[0]
        const events = skipToLastEvent ? buttonEvent.events.slice(-1) : buttonEvent.events
        events.forEach(async e => {
          if (buttonEvent.tag !== this.tag ||
              buttonEvent.nonce !== this.lastButtonInfo.nonce ||
              e.buttonPress > this.lastButtonInfo.buttonPress ||
              e.buttonRelease > this.lastButtonInfo.buttonRelease) {
            await this.$store.dispatch('provision/setTag', {
              tag: buttonEvent.tag,
              buttonInfo: {
                tag: buttonEvent.tag,
                nonce: buttonEvent.nonce,
                buttonPress: e.buttonPress,
                buttonRelease: e.buttonReleases,
                tapDelta: e.tapDelta
              }
            })
          }
        })
      }
    },
    async invoiceZonesInOrg (org) {
      const { data } = await api.get(`zones?level=site&parent=${org}`)
      return data || []
    },
    async lookupZone (id) {
      return (await api.get(`zones/${id}`)) || {}
    }
  }
}
