exports.markerLabels = {
  guard: 'Gateway guard',
  behind_tv: 'Behind TV',
  under_desk: 'Under Desk',
  stool: 'Requires Stool',
  red_outlet: 'Red Outlet',
  ladder_6ft: 'Requires 6\' Ladder'
}

exports.outdoorMarkerLabels = {
  courtyard: 'Courtyard',
  ramp_down: 'Ramp Down',
  ramp_up: 'Ramp Up',
  stairwell: 'Stairwell',
  flat_mount: 'Flat Mount',
  side_mount: 'Side Mount'
}
