import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

const Login = () => import('./views/Login.vue')
const Alert = () => import('./views/Alert.vue')
const Alerts = () => import('./views/Alerts.vue')
const HomeVisits = () => import('./views/HomeVisits.vue')
const Assets = () => import('./views/Assets.vue')
const AssetFloorPlan = () => import('./views/AssetFloorPlan.vue')
const FloorPlans = () => import('./views/FloorPlans.vue')
const LogAlerts = () => import('./views/LogAlerts.vue')
const LogSync = () => import('./views/LogSync.vue')
const LogAudit = () => import('./views/LogAudit.vue')
const LogBatch = () => import('./views/LogBatch.vue')
const Tags = () => import('./views/Tags.vue')
const Tag = () => import('./views/Tag.vue')
const Gateways = () => import('./views/Gateways.vue')
const Gateway = () => import('./views/Gateway.vue')
const Consoles = () => import('./views/Consoles.vue')
const DistributionStations = () => import('./views/DistributionStations.vue')
const Device = () => import('./views/Device.vue')
const DeviceReplacement = () => import('./views/DeviceReplacement.vue')
const People = () => import('./views/People.vue')
const Subscriptions = () => import('./views/Subscriptions.vue')
const Zones = () => import('./views/Zones.vue')
const Users = () => import('./views/Users.vue')
const Keys = () => import('./views/Keys.vue')
const BigQueryReport = () => import('./views/BigQueryReport.vue')
const Reports = () => import('./views/Reports.vue')
const HealthDisconnectedGateways = () => import('./views/HealthDisconnectedGateways.vue')
const ReportTagsReplacement = () => import('./views/ReportTagsReplacement.vue')
const ReportTagsNotSeen = () => import('./views/ReportTagsNotSeen.vue')
const GatewayBatchOps = () => import('./views/GatewayBatchOps.vue')
const TagBatchOps = () => import('./views/TagBatchOps.vue')
const Orders = () => import('./views/Orders.vue')
const OrderRecipients = () => import('./views/OrderRecipients.vue')
const DistributionPick = () => import('./views/distribution/Pick.vue')
const DistributionHome = () => import('./views/distribution/Home.vue')
const DistributionTap = () => import('./views/distribution/Tap.vue')
const DistributionScan = () => import('./views/distribution/Scan.vue')
const DistributionStatus = () => import('./views/distribution/Status.vue')
const DistributionCurrentTags = () => import('./views/distribution/CurrentTags.vue')
const DistributionActivate = () => import('./views/distribution/Activate.vue')
const DistributionSuccess = () => import('./views/distribution/Success.vue')
const FactoryHome = () => import('./views/factory/Home.vue')
const WarehouseHome = () => import('./views/warehouse/Home.vue')
const WarehouseReturns = () => import('./views/warehouse/Returns.vue')
const WarehouseLogistics = () => import('./views/warehouse/Logistics.vue')
const WarehouseInventory = () => import('./views/warehouse/Inventory.vue')
const Roles = () => import('./views/Roles.vue')
const Errors = () => import('./views/Errors.vue')
const Analytics = {
  Incidents: () => import('./views/analytics/Incidents.vue'),
  ResponseTimes: () => import('./views/analytics/ResponseTimes.vue')
}

Vue.use(Router)

const IS_DEV_ENV = (process.env.NODE_ENV === 'development')

const devRoutes = []

const routes = [{
  path: '/',
  name: 'home'
}, {
  path: '/login',
  name: 'login',
  component: Login,
  meta: {
    noAuth: true,
    layout: 'empty'
  }
}, {
  path: '/alerts',
  name: 'alerts',
  component: Alerts,
  meta: {
    rule: (can) => can('list', 'Alert')
  }
}, {
  path: '/health/disconnected-gateways',
  name: 'disconnected-gateways',
  component: HealthDisconnectedGateways,
  meta: {
    rule: (can) => can('read', 'Report', 'metrics')
  }
}, {
  path: '/home-visits',
  name: 'home-visits',
  component: HomeVisits,
  meta: {
    rule: (can) => can('list', 'HomeVisit')
  }
}, {
  path: '/gateways',
  name: 'gateways',
  component: Gateways,
  props: true,
  meta: {
    rule: (can) => can('list', 'Gateway')
  }
}, {
  path: '/outdoor/:type',
  name: 'outdoor-gateways',
  component: Gateways,
  props: true,
  meta: {
    rule: (can) => can('list', 'Gateway')
  }
}, {
  path: '/consoles',
  name: 'consoles',
  component: Consoles,
  meta: {
    rule: (can) => can('list', 'Console')
  }
}, {
  path: '/distribution-stations',
  name: 'distribution-stations',
  component: DistributionStations,
  meta: {
    rule: (can) => can('list', 'DistributionStation')
  }
}, {
  path: '/tags',
  name: 'tags',
  component: Tags,
  props: true,
  meta: {
    rule: (can) => can('list', 'Tag')
  }
}, {
  path: '/people',
  name: 'people',
  component: People,
  props: true,
  meta: {
    rule: (can) => can('list', 'Person')
  }
}, {
  path: '/floorplans/:floorId?',
  name: 'floorplans',
  component: FloorPlans,
  props: true,
  meta: {
    rule: (can) => can('list', 'FloorPlan'),
    layout: window.GatewayMobileApp ? 'empty' : 'default'
  }
}, {
  path: '/log/alerts',
  name: 'alertLogs',
  component: LogAlerts,
  meta: {
    rule: (can) => can('list', 'LogAlert')
  }
}, {
  path: '/log/sync',
  name: 'syncLogs',
  component: LogSync,
  meta: {
    rule: (can) => can('list', 'LogSync')
  }
}, {
  path: '/log/audit/:requestId?',
  name: 'auditLogs',
  component: LogAudit,
  props: true,
  meta: {
    rule: (can) => can('list', 'LogAudit')
  }
}, {
  path: '/log/batch',
  name: 'batchOperationLogs',
  component: LogBatch,
  props: true,
  meta: {
    rule: (can) => can('list', 'LogBatch')
  }
}, {
  path: '/reports',
  name: 'reports',
  component: Reports,
  meta: {
    rule: (can) => can('read', 'Report')
  }
}, {
  path: '/reports/tags-flagged/details',
  name: 'tags-flagged-report-details',
  component: Tags,
  props: true,
  meta: {
    rule: (can) => can('read', 'Report', 'tags')
  }
}, {
  path: '/reports/tags-replacement',
  name: 'tags-replacement-report',
  component: ReportTagsReplacement,
  meta: {
    rule: (can) => can('read', 'Report', 'replacement')
  }
}, {
  path: '/reports/tags-replacement/details',
  name: 'tags-replacement-report-details',
  component: Tags,
  props: true,
  meta: {
    rule: (can) => can('read', 'Report', 'replacement')
  }
}, {
  path: '/reports/tags-not-seen',
  name: 'tags-not-seen-report',
  component: ReportTagsNotSeen,
  meta: {
    rule: (can) => can('read', 'Report', 'tags')
  }
}, {
  path: '/reports/tags-not-seen/details',
  name: 'tags-not-seen-report-details',
  component: Tags,
  props: true,
  meta: {
    rule: (can) => can('read', 'Report', 'tags')
  }
}, {
  path: '/reports/:id',
  name: 'bigquery-report',
  component: BigQueryReport,
  props: true,
  meta: {
    rule: (can) => can('read', 'Report')
  }
}, {
  path: '/operations/gateways',
  name: 'gateway-batch-ops',
  component: GatewayBatchOps,
  props: true,
  meta: {
    rule: (can) => can('list', 'Gateway') && can('update', 'Gateway')
  }
}, {
  path: '/operations/tags',
  name: 'tag-batch-ops',
  component: TagBatchOps,
  props: true,
  meta: {
    rule: (can) => can('list', 'Tag') && can('update', 'Tag')
  }
}, {
  path: '/operations/orders',
  name: 'orders-ops',
  component: Orders,
  props: true,
  meta: {
    rule: (can) => can('list', 'Order') && can('update', 'Order')
  }
}, {
  path: '/operations/orders/recipients',
  name: 'order-recipients-ops',
  component: OrderRecipients,
  props: true,
  meta: {
    rule: (can) => can('list', 'Order') && can('update', 'Order')
  }
}, {
  path: '/tag/:id',
  name: 'tag',
  component: Tag,
  props: true,
  meta: {
    rule: (can) => can('read', 'Tag')
  }
}, {
  path: '/gateway/:id',
  name: 'gateway',
  component: Gateway,
  props: true,
  meta: {
    rule: (can) => can('read', 'Gateway')
  }
}, {
  path: '/v1/:type/:id([0-9A-Fa-f]{12})',
  name: 'deviceV1',
  component: Device,
  props: true,
  meta: {
    noAuth: true,
    layout: 'logo'
  }
}, {
  path: '/device/replacement',
  name: 'device-replacement',
  component: DeviceReplacement,
  meta: {
    layout: 'kiosk',
    rule: (can) => can('update', 'Gateway')
  }
}, {
  path: '/subscriptions',
  name: 'subscriptions',
  component: Subscriptions,
  meta: {
    rule: (can) => can('list', 'Subscription')
  }
}, {
  path: '/zones',
  name: 'zones',
  component: Zones,
  meta: {
    rule: (can) => can('list', 'Zone')
  }
}, {
  path: '/users',
  name: 'users',
  component: Users,
  meta: {
    rule: (can) => can('list', 'User')
  }
}, {
  path: '/roles',
  name: 'roles',
  component: Roles,
  meta: {
    rule: (can) => can('list', 'Role')
  }
}, {
  path: '/keys',
  name: 'keys',
  component: Keys,
  meta: {
    rule: (can) => can('list', 'User')
  }
}, {
  path: '/alert/:id',
  name: 'alert',
  component: Alert,
  props: true,
  meta: {
    rule: (can) => can('read', 'Alert')
  }
}, {
  path: '/error/:code',
  name: 'error',
  component: Errors,
  props: true,
  meta: {
    noAuth: true,
    layout: 'logo'
  }
}, {
  path: '/distribution',
  name: 'distribution-pick',
  component: DistributionPick,
  props: true,
  meta: {
    layout: 'empty',
    authMethod: 'key',
    rule: (can) => can('read', 'DistributionStation')
  }
}, {
  path: '/distribution/:station',
  redirect: '/distribution/:station/home'
}, {
  path: '/distribution/:station/home',
  name: 'distribution-home',
  component: DistributionHome,
  props: true,
  meta: {
    layout: 'kiosk',
    authMethod: 'key',
    rule: (can) => can('read', 'Person')
  }
}, {
  path: '/distribution/:station/tap',
  name: 'distribution-tap',
  component: DistributionTap,
  props: true,
  meta: {
    layout: 'kiosk',
    rule: (can) => can('read', 'Tag')
  }
}, {
  path: '/distribution/:station/scan',
  name: 'distribution-scan',
  component: DistributionScan,
  props: true,
  meta: {
    layout: 'kiosk',
    rule: (can) => can('read', 'Tag')
  }
}, {
  path: '/distribution/:station/status',
  name: 'distribution-status',
  component: DistributionStatus,
  props: true,
  meta: {
    layout: 'kiosk',
    rule: (can) => can('update', 'Tag')
  }
}, {
  path: '/distribution/:station/activate',
  name: 'distribution-activate',
  component: DistributionActivate,
  props: true,
  meta: {
    layout: 'kiosk',
    rule: (can) => can('update', 'Tag')
  }
}, {
  path: '/distribution/:station/current-tags',
  name: 'distribution-current-tags',
  component: DistributionCurrentTags,
  props: true,
  meta: {
    layout: 'empty',
    rule: (can) => can('update', 'Tag')
  }
}, {
  path: '/distribution/:station/success',
  name: 'distribution-success',
  component: DistributionSuccess,
  props: true,
  meta: {
    layout: 'kiosk'
  }
}, {
  path: '/factory/home',
  name: 'factory-home',
  component: FactoryHome,
  meta: {
    layout: 'kiosk'
  }
}, {
  path: '/warehouse/home',
  name: 'warehouse-home',
  component: WarehouseHome,
  props: true,
  meta: {
    layout: 'kiosk'
  }
}, {
  path: '/warehouse/returns',
  name: 'warehouse-returns',
  component: WarehouseReturns,
  props: true,
  meta: {
    layout: 'kiosk',
    rule: (can) => can('list', 'Inventory')
  }
}, {
  path: '/warehouse/logistics/receive',
  name: 'warehouse-logistics-receive',
  component: WarehouseLogistics,
  props: true,
  meta: {
    layout: 'kiosk',
    rule: (can) => can('list', 'Inventory')
  }
}, {
  path: '/warehouse/logistics/ship/:order?',
  name: 'warehouse-logistics-ship',
  component: WarehouseLogistics,
  props: true,
  meta: {
    layout: 'kiosk',
    rule: (can) => can('list', 'Inventory')
  }
}, {
  path: '/warehouse/inventory',
  name: 'warehouse-inventory',
  component: WarehouseInventory,
  meta: {
    layout: 'kiosk',
    rule: (can) => can('read', 'Tag')
  }
}, {
  path: IS_DEV_ENV ? '/:id([0-9A-Fa-f]{12}|tag[0-9]+|gateway[0-9]+)' : '/:id([0-9A-Fa-f]{12})',
  name: 'device',
  component: Device,
  props: true,
  meta: {
    noAuth: true,
    layout: 'empty'
  }
}, {
  path: '/analytics/incidents',
  name: 'analytics-incidents',
  component: Analytics.Incidents,
  meta: {
    rule: (can) => can('read', 'Report', 'analytics'),
    layout: 'nofooter'
  }
}, {
  path: '/analytics/response-times',
  name: 'analytics-response-times',
  component: Analytics.ResponseTimes,
  meta: {
    rule: (can) => can('read', 'Report', 'analytics'),
    layout: 'nofooter'
  }
}, {
  path: '/assets',
  name: 'assets',
  component: Assets,
  props: true,
  meta: {
    rule: (can) => can('list', 'Asset')
  }
},
{
  path: '/assets/:id',
  name: 'assets-floor-plan',
  component: AssetFloorPlan,
  props: true,
  meta: {
    rule: (can) => can('read', 'Asset')
  }
},
...(IS_DEV_ENV ? devRoutes : []),
{
  path: '*',
  redirect: '/error/404'
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

function authReady () {
  return new Promise((resolve) => {
    if (!store.getters['auth/loading']) {
      return resolve()
    }

    const unwatch = store.watch(
      (state, getters) => getters['auth/loading'],
      () => {
        unwatch()
        resolve()
      }
    )
  })
}

function home (after) {
  const can = store.getters['auth/can']
  const route = routes.find(route => route.meta && route.meta.rule && route.meta.rule(can))
  const value = route ? route.name : { name: 'error', params: { code: 403 } }
  if (value === 'disconnected-gateways') {
    return { name: 'disconnected-gateways' }
  } else {
    return value
  }
}

async function ifLoggedIn (to, from, next) {
  await authReady()
  const after = (to.matched.find(({ name }) => name && name !== 'login') || {}).path
  const can = store.getters['auth/can']
  const user = store.getters['auth/user']
  if (user) {
    if (user.role === 'distribution' && !to.name.includes('distribution')) {
      return router.push({ name: 'distribution-pick' })
    } else if (user.role === 'factory' && !to.name.includes('factory')) {
      return router.push({ name: 'factory-home' })
    } else if (user.role === 'warehouse' && !to.name.includes('warehouse')) {
      return router.push({ name: 'warehouse-home' })
    } else if (user.role === 'noAccess') {
      return router.push({ name: 'error', params: { noAccess: true } })
    }
    if (to.name === 'home') {
      return router.push(home())
    }
    if (to.meta.rule && !to.meta.rule(can)) {
      return next({ name: 'error', params: { code: 403 } })
    }
    return next()
  }
  next({
    name: 'login',
    query: {
      ...(after && { after }),
      ...(to.meta.authMethod && { method: to.meta.authMethod }),
      ...(to.query ? to.query : {})
    }
  })
}

async function ifNotLoggedIn (to, from, next) {
  await authReady()
  const after = to.query.after
  const user = store.getters['auth/user']
  if (!user || (user.role === 'noAccess' && !after)) {
    return next()
  } else if (user.role === 'noAccess' && after) {
    return router.push({ name: 'error', params: { noAccess: true } })
  } else if (after) {
    if (after && after.startsWith('/')) {
      return router.push({ path: after })
    }
  }
  return router.push(home())
}

router.beforeEach((to, from, next) => {
  const noAuth = to.matched.some(record => record.meta.noAuth)
  if (to.name === 'login') {
    return ifNotLoggedIn(to, from, next)
  } else if (!noAuth) {
    return ifLoggedIn(to, from, next)
  }
  next()
})

export default router
