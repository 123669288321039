import Vue from 'vue'
import api from '@/lib/api'

// initial state
const state = {
  zones: {},
  slugs: [],
  loading: false
}

// getters
const getters = {
  orgs: state => id => {
    let z = state.zones[id]
    if (z && z.level) {
      if (z.level === 'root') {
        // return my kids ...
        throw new Error('unimpld')
      } else if (z.level === 'org') {
        // return me
        return [z.id]
      } else {
        // check my parents
        while (z = state.zones[z.parent]) { // eslint-disable-line no-cond-assign
          if (z.level === 'org') {
            return [z.id]
          }
        }
      }
    }
    return []
  },
  zones: state => state.zones,
  zone: state => id => state.zones[id],
  subzones: state => (parentId) => {
    if (
      !parentId ||
      !state.zones[parentId] ||
      !state.zones[parentId].isLoaded
    ) return
    return Object.values(state.zones).filter(z => z.parent === parentId)
  },
  pathTo: (state, getters) => (id) => {
    const zone = state.zones[id]
    if (!zone || !zone.parent) return
    const ancestorPath = getters.pathTo(zone.parent)
    return ancestorPath ? `${ancestorPath} > ${zone.name}` : zone.name
  },
  locationTo: (state, getters) => (id, full) => {
    const zone = state.zones[id]
    if (!zone) return
    const ancestors = getters.locationTo(zone.parent, full)
    return ancestors ? [...ancestors, full ? zone : id] : [full ? zone : id]
  },
  slugs: state => state.slugs,
  loading: state => state.loading
}

// actions
const actions = {
  loadPath: async ({ commit, state, dispatch }, { admin, zone, report, force, peers, context }) => {
    const id = zone
    if (id && (!state.zones[id] || !state.zones[id].isLoaded || force)) {
      commit('SET_LOADING', true)
      const params = {
        children: true,
        ...(context && { context }),
        ...(peers && { peers: true }),
        ...(admin && { admin: true }),
        ...(report && { 'report.type': report.type, 'report.start': report.start, 'report.end': report.end })
      }
      const response = await api.get(`zones/${id}`, { params })
        .finally(() => commit('SET_LOADING', false))
      // TODO
      // - [ ] what if there is no response.data?
      const { data } = response
      data.isLoaded = true
      const extras = []
      if (!data.children || !data.children.length) {
        delete data.children
      } else {
        extras.push(...data.children)
      }
      if (!(data.peers && data.peers.length)) {
        delete data.peers
      } else {
        extras.push(...data.peers)
      }
      commit('UPDATE_ZONES', [data, ...extras]) // ...(data.children || [])])
      if (data.parent) {
        return dispatch('loadPath', { zone: data.parent, context })
      }
    }
  },
  loadSlugs: async ({ commit, state, dispatch }, id) => {
    commit('SET_LOADING', true)
    const { data: zones } = await api.get('zones?slug=exists')
      .finally(() => commit('SET_LOADING', false))
    commit('UPDATE_SLUGS', zones)
    return zones
  },
  add: async ({ commit, state }, { name, level, parent, config, slug, auth }) => {
    commit('SET_LOADING', true)
    const { data: zone } = await api.post('zones', { name, level, parent, config, slug, auth })
      .finally(() => commit('SET_LOADING', false))
    commit('UPDATE_ZONE', zone)
    return zone
  },
  update: async ({ commit, state }, { id, name, level, parent, config, slug, auth, peers, followers }) => {
    commit('SET_LOADING', true)
    const { data: zone } = await api.put(`zones/${id}`, { name, level, parent, config, slug, auth, peers, followers })
      .finally(() => commit('SET_LOADING', false))
    commit('UPDATE_ZONE', zone)
    return zone
  },
  delete: async ({ commit, state }, id) => {
    commit('SET_LOADING', true)
    await api.delete(`zones/${id}`)
      .finally(() => commit('SET_LOADING', false))
    commit('DELETE_ZONE', id)
  },
  clear: ({ commit }) => {
    commit('CLEAR_ZONES')
  }
}

// mutations
const mutations = {
  CLEAR_ZONES (state) {
    state.zones = {}
    state.slugs = []
  },
  UPDATE_ZONE (state, zone) {
    Vue.set(state.zones, zone.id, zone)
  },
  UPDATE_ZONES (state, zones) {
    zones.forEach(zone => {
      if (!state.zones[zone.id] || !state.zones[zone.id].isLoaded) {
        Vue.set(state.zones, zone.id, zone)
      }
    })
  },
  DELETE_ZONE (state, id) {
    Vue.delete(state.zones, id)
  },
  UPDATE_SLUGS (state, zones) {
    state.slugs = zones
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
